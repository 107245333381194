<script>
import ClientDisabledBanner from "./components/global/client_disabled_banner.vue";
import GlobalModals from "./components/global/globalModals/global_modals.vue";
import MaintenanceBanner from "./components/global/maintenance_banner.vue";
import Navbar from "./components/global/navbar.vue";
import PausedBanner from "./components/global/paused_banner.vue";

export default {
  inject: ["growthBook"],

  components: {
    ClientDisabledBanner,
    MaintenanceBanner,
    Navbar,
    PausedBanner,
    GlobalModals,
  },

  data() {
    return {
      isNestedDropdownVisible: false,
      webClientDisabled: false,
      webClientDisabledMessage: undefined,
    };
  },

  computed: {
    hideNavbar() {
      return this.$route?.meta?.hideNavbar;
    },
  },

  beforeMount() {
    this.growthBook?.setAttributes({ email: this.$store.state.user?.email });
    this.webClientDisabledMessage = this.growthBook?.getFeatureValue(
      "web-client-disabled-message"
    );
    this.webClientDisabled = !!this.webClientDisabledMessage;

    this.initNestedDropdowns();
  },

  methods: {
    initNestedDropdowns() {
      this.$root.$on("bv::dropdown::show", (bvEvent) => {
        const parentDropdownName = bvEvent?.vueTarget?.$parent?.$options?.name;

        if (
          parentDropdownName === "EgDropdown" ||
          parentDropdownName === "BFormDatepicker"
        ) {
          this.isNestedDropdownVisible = true;
        }
      });

      this.$root.$on("bv::dropdown::hide", (bvEvent) => {
        const parentDropdownName = bvEvent?.vueTarget?.$parent?.$options?.name;

        if (
          parentDropdownName === "EgDropdown" ||
          parentDropdownName === "BFormDatepicker"
        ) {
          this.isNestedDropdownVisible = false;
        }

        if (this.isNestedDropdownVisible) {
          bvEvent.preventDefault();
        }
      });
    },
  },
};
</script>
<template>
  <div id="app">
    <div v-if="webClientDisabled">
      <client-disabled-banner
        :clientDisabledMessage="webClientDisabledMessage"
      />
    </div>
    <div v-else>
      <navbar v-if="!hideNavbar" />
      <maintenance-banner />
      <paused-banner />
      <router-view />
      <global-modals />
    </div>
  </div>
</template>
