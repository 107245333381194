<template>
  <b-sidebar
    id="rubric-library-sidebar"
    ref="sidebarRef"
    width="535px"
    right
    shadow
    lazy
    no-header
    backdrop
    backdrop-variant="transparent"
    sidebar-class="bg-white"
    class="rubric-sidebar"
  >
    <template #default="{ hide }">
      <div class="pl-3 d-flex align-items-center justify-content-between">
        <div class="title-text">Rubrics</div>
        <b-button variant="link" size="lg" class="text-dark" @click="hide">
          <b-icon size="md" icon="x" />
        </b-button>
      </div>
      <div>
        <b-tabs nav-class-wrapper="mx-3">
          <b-tab title="Platform rubrics" :active="rubrics.length === 0">
            <rubric-filter v-model="rubricLibrarySearchQuery" />
            <eg-selectable-table
              :selectable="false"
              :items="filteredRubricLibrary"
              :fields="fields"
            >
            </eg-selectable-table>
          </b-tab>
          <b-tab title="My rubrics" :active="rubrics.length > 0">
            <rubric-filter v-model="selfRubricSearchQuery" />
            <eg-selectable-table
              v-if="rubrics.length > 0"
              :selectable="false"
              :items="filteredSelfRubrics"
              :fields="fields"
            >
            </eg-selectable-table>
            <empty-state
              v-else
              title="You haven’t created any rubrics yet."
              button-text="Create new rubric"
              description=""
              :button-path="`/rubric/new?redirectTo=${$route.fullPath}`"
              class="mt-4 ml-auto mr-auto"
            >
              <img
                src="../../assets/illustrations/empty-state-rubrics.svg"
                alt="EssayGrader Empty State"
              />
            </empty-state>
          </b-tab>
        </b-tabs>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { getRubrics } from "@/api";
import { generatePlatformRubrics } from "@/utils/generatePlatformRubrics";
import EmptyState from "@/components/global/empty_state.vue";
import RubricFilter from "./rubric_filter.vue";
import EgSelectableTable from "@/components/global/eg_selectable_table";

export default {
  name: "RubricLibrarySidebar",

  components: {
    EmptyState,
    RubricFilter,
    EgSelectableTable,
  },

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      rubricLibrarySearchQuery: "",
      selfRubricSearchQuery: "",
      rubricsLoading: false,
      rubrics: [],
      platformRubrics: generatePlatformRubrics(),
      fields: [
        {
          key: "name",
          label: "Rubric name",
          class: "name-row",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "createdBy",
          label: "Created by",
          formatter: (value) => {
            return value || "Me";
          },
        },
        {
          key: "action",
          label: "",
          formatter: (value, key, item) => {
            return {
              action: (item) => {
                this.selectRubric(item);
              },
              label: item.id === this.value?.id ? "Selected" : "Select",
              props: {
                variant: "outline-info",
                class: { "button-secondary-info": item.id === this.value?.id },
              },
            };
          },
        },
      ],
    };
  },

  computed: {
    groupedRubrics() {
      return this.rubrics.reduce((acc, rubric) => {
        const type = rubric.type;

        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(rubric);

        return acc;
      }, {});
    },

    filteredRubricLibrary() {
      return this.platformRubrics.filter((rubric) =>
        rubric.name
          .toLowerCase()
          .includes(this.rubricLibrarySearchQuery.toLowerCase())
      );
    },

    filteredSelfRubrics() {
      return this.rubrics.filter((rubric) =>
        rubric.name
          .toLowerCase()
          .includes(this.selfRubricSearchQuery.toLowerCase())
      );
    },
  },

  methods: {
    selectRubric(value) {
      this.$emit("input", value);
    },

    async fetchRubrics() {
      try {
        this.rubricsLoading = true;

        const { data } = await getRubrics();

        this.rubricsLoading = false;

        this.rubrics = data;
      } catch (error) {
        this.rubricsLoading = false;
        this.$showToastError(error.response?.data?.error);
      }
    },
  },

  async mounted() {
    await this.fetchRubrics();

    if (this.$route.query.rubricId) {
      this.$refs.sidebarRef.localShow = true;
      const rubric = this.rubrics.find(
        (rubric) => +rubric.id === +this.$route.query.rubricId
      );

      rubric && this.selectRubric(rubric);
    }
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  color: var(--Text-Body, #212529);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.rubric-sidebar {
  &::v-deep {
    .nav-tabs {
      padding: 0 1rem;
    }

    #rubric-library-sidebar {
      height: calc(100vh - var(--navbar-height));
      top: var(--navbar-height);
    }

    .b-table-sticky-header {
      max-height: calc(100vh - 210px);
      margin-bottom: 0;
      padding: 0 16px;
    }

    .name-row {
      max-width: 210px;
      overflow: hidden;
    }
  }
}
</style>
