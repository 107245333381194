const gradingLevels = ["Elementary", "Middle school", "High school", "College"];
const essayTypes = ["Persuasive", "Descriptive", "Narrative", "Expository"];

export const generatePlatformRubrics = () => {
  const combinations = [];
  for (const gradeLevel of gradingLevels) {
    for (const essayType of essayTypes) {
      const name = `${gradeLevel} - ${essayType}`;
      combinations.push({
        id: name,
        name,
        gradeLevel: gradeLevel.replace(" ", "").toLowerCase(),
        essayType: essayType.toLowerCase(),
        createdBy: "EssayGrader",
        type: "Simple",
      });
    }
  }
  return combinations;
};
