<script>
import { createRubric } from "../api";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import RubricForm from "../components/rubrics/rubric_form.vue";
import fireConfetti from "@/utils/fireConfetti";

export default {
  name: "NewRubricView",
  components: {
    Breadcrumbs,
    RubricForm,
  },
  data() {
    return {
      loading: true,
      error: "",
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Rubrics",
          href: "/rubrics",
        },
        {
          text: "New",
          active: true,
        },
      ];
    },
  },
  methods: {
    async createRubric(rubricData) {
      try {
        const { data } = await createRubric(rubricData);

        fireConfetti();

        const redirectTo = this.$route.query.redirectTo || "/rubrics";
        const redirectToHasQuery = redirectTo.includes("?");
        this.$root.$bvToast.toast("The rubric was created successfully.", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-center",
        });
        this.$router.push(
          `${redirectTo}${redirectToHasQuery ? "&" : "?"}rubricId=${data.id}`
        );
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row class="mb-3">
        <b-col v-if="error" cols="12">
          <b-alert variant="danger" class="mb-4" show>
            {{ error }}
          </b-alert>
        </b-col>
        <b-col lg="12">
          <h1 class="purple-text">New Rubric</h1>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <rubric-form @rubricSubmitted="createRubric" />
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
