<template>
  <div>
    <b-dropdown
      class="export-dropdown mr-2"
      size="sm"
      right
      variant="outline-info"
      text="Export"
    >
      <b-dropdown-item @click="exportTo('classroom')">
        Google Classroom
      </b-dropdown-item>
      <b-dropdown-item @click="exportTo('pdf')"> PDF </b-dropdown-item>
      <b-dropdown-item @click="exportTo('csv')"> CSV </b-dropdown-item>
    </b-dropdown>
    <b-button size="sm" variant="danger" @click="onClickDelete">
      Delete
    </b-button>
  </div>
</template>

<script>
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";

export default {
  name: "EssayActions",

  // this.showDeleteConfirmationModal
  mixins: [deleteConfirmationModalMixin],

  props: {
    selectedAll: {
      type: Boolean,
      default: false,
    },

    selectedIds: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    exportTo(type) {
      this.$emit("export", {
        type,
        selectedIds: this.selectedIds,
      });
    },

    onClickDelete() {
      this.showDeleteConfirmationModal({
        title: `Are you sure you want to delete ${
          this.selectedAll ? "all" : this.selectedIds.length
        } essay${this.selectedAll || this.selectedIds.length > 1 ? "s" : ""}?`,
        message: `Essays cannot be accessed after deletion. Queued essays won't be deleted. This action cannot be undone.`,
      })
        .then((value) => {
          if (value) {
            this.$emit("delete", this.selectedIds);
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
  },
};
</script>
