export default {
  methods: {
    trackAndRedirectToUpgrade(reason, params = {}) {
      this.$trackEvent(`upgrade_clicked`, {
        reason,
        ...params,
      });
      this.$router.push("/account");
    },
  },
};
