export const criteriaChecker = ({ scaleType, criterias }) => {
  // ** TODO validate grading criteria **
  // Input should be text with no special characters
  // Input should not contain numbers
  const maxTitleLength = 255;
  const maxTitleLengthError = `The criteria name is too long. The maximum is ${maxTitleLength} characters.`;

  let proceed = true;
  let error = "";

  criterias.forEach((criteria) => {
    if (
      scaleType === "Default" &&
      !criteria?.criteriaGrades?.[0]?.description
    ) {
      proceed = false;
      error = "Criteria description is required.";
    }

    if (
      (scaleType === "Simple" || scaleType === "Default") &&
      criteria?.criteriaGrades?.[0]?.description.length > 255
    ) {
      proceed = false;
      error = maxTitleLengthError;
    }

    if (scaleType === "Advanced" && criteria?.name?.length > 255) {
      proceed = false;
      error = maxTitleLengthError;
    }

    if (scaleType === "Simple" || scaleType === "Advanced") {
      criteria.criteriaGrades.forEach((grade) => {
        if (!grade.description || grade.maxGrade < 0) {
          proceed = false;
          error = "Description and max grade are required.";
        }
      });
    }
  });

  return { canCreateRubric: proceed, rubricError: error };
};

export const MAX_COUNT_WORDS = 25000;
const getWordCount = (str) =>
  str.trim().length ? str.trim().split(" ").length : 0;
export const getWordsCountOfAllText = (criteriasArr) => {
  return criteriasArr.reduce((acc, criteria) => {
    return (
      acc +
      getWordCount(criteria.point) +
      criteria.scales.reduce((acc, scale) => {
        return acc + getWordCount(scale.description);
      }, 0)
    );
  }, 0);
};
