<script>
import debounce from "../../utils/debounce";
import {
  criteriaChecker,
  MAX_COUNT_WORDS,
  getWordsCountOfAllText,
} from "./utils";
import rubricScale from "./rubric_scale.vue";
import rubricPreviewTable from "./rubric_previewTable.vue";
import rubricIntensityRadioGroup from "./rubric_intensity_radio_group.vue";

const getDefaultCriteria = () => ({
  point: "",
  scale: 4,
  scales: [
    {
      description: "",
      scale: 4,
    },
  ],
});

export default {
  name: "RubricForm",
  components: {
    rubricScale,
    rubricPreviewTable,
    rubricIntensityRadioGroup,
  },
  props: {
    noButtons: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      required: false,
    },
    rubric: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      canValidate: false,
      name: "",
      intensity: "moderate",
      scaleType: "Simple",
      criteria: [getDefaultCriteria()],
      error: "",
      isPreview: false,
    };
  },

  computed: {
    buttonText() {
      return this.editing ? "Save changes" : "Create rubric";
    },
    canAddCriteriaPoint() {
      return this.criteria.length < 50;
    },
    isUsingCustomScale() {
      return ["Simple", "Advanced"].includes(this.scaleType);
    },
    isUsingAdvancedScale() {
      return this.scaleType === "Advanced";
    },
  },
  methods: {
    updateCriteriaValue(value, index) {
      this.criteria[index].point = value;
      this.debounceCheckWordsCountOfAllText();
    },
    updateCriteriaScale(scale, index) {
      this.criteria[index].scale = scale;
    },
    updateCriteriaScaleDescriptionValue(description, index, scaleIndex) {
      this.criteria[index].scales[scaleIndex].description = description;
      this.debounceCheckWordsCountOfAllText();
    },
    updateCriteriaScaleDescriptionScale(scale, index, scaleIndex) {
      this.criteria[index].scales[scaleIndex].scale = scale;
    },

    addCriteriaPoint() {
      if (this.canAddCriteriaPoint) {
        this.criteria.push(getDefaultCriteria());
      }
    },

    deleteCriteria(index) {
      this.criteria.splice(index, 1);
    },

    addCriteriaDescription(index) {
      this.criteria[index].scales.push({
        description: "",
        scale: 0,
      });
    },

    deleteCriteriaDescription(index, scaleIndex) {
      this.criteria[index].scales.splice(scaleIndex, 1);
    },

    debounceCheckWordsCountOfAllText: debounce(function () {
      this.checkWordsCountOfAllText();
    }, 2000),

    checkWordsCountOfAllText() {
      if (getWordsCountOfAllText(this.criteria) > MAX_COUNT_WORDS) {
        this.$showToastError(
          "The maximum word count for a rubric is 25,000 words. Please reduce the word count on your rubric and try again."
        );
        return true;
      }

      return false;
    },

    // return data or false to detect error or data
    onSubmit(e) {
      e && e.preventDefault();

      this.canValidate = true;

      if (this.checkWordsCountOfAllText()) {
        return false;
      }

      if (!this.name) {
        this.$showToastError("Rubric name is required.");

        return false;
      }

      if (this.name.length > 255) {
        this.$showToastError(
          "The rubric name is too long. The maximum is 255 characters."
        );

        return false;
      }

      if (!this.criteria[0].point) {
        this.$showToastError(
          "At least one grading criteria point is required."
        );

        return false;
      }

      let criterias = this.criteria.map((criteria) => {
        const criteriaData = {};

        if (this.isUsingAdvancedScale) {
          criteriaData.name = criteria.point;
        }

        criteriaData.criteriaGrades = criteria.scales.map((scale) => ({
          description: this.isUsingAdvancedScale
            ? scale.description
            : criteria.point,
          maxGrade: this.isUsingAdvancedScale ? scale.scale : criteria.scale,
        }));

        return criteriaData;
      });

      if (this.scaleType === "Default") {
        criterias = this.criteria.map((obj) => {
          return {
            criteriaGrades: [
              {
                description: obj.point,
              },
            ],
          };
        });
      }

      const rubricData = {
        name: this.name,
        type: this.scaleType,
        intensity: this.intensity,
        criterias,
      };

      const { canCreateRubric, rubricError } = criteriaChecker({
        scaleType: this.scaleType,
        criterias: rubricData.criterias,
      });

      if (!canCreateRubric) {
        this.$showToastError(rubricError);

        return false;
      }

      this.$emit("rubricSubmitted", rubricData);

      return rubricData;
    },

    showDeleteConfirmation() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this rubric?", {
          title: "Delete Confirmation",
          size: "ml",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((needDelete) => {
          needDelete && this.$emit("delete");
        })
        .catch((err) => {
          this.$showToastError("Something went wrong. Please try again.", {
            title: "Delete error",
            solid: true,
          });

          console.log(err);

          return;
        });
    },
  },
  mounted() {
    // set data when the form is used for editing
    if (this.rubric && this.editing) {
      this.name = this.rubric.name;
      this.scaleType = this.rubric.type;

      if (this.rubric.type === "Default" || this.rubric.type === "Simple") {
        this.criteria = this.rubric.criterias.map((criteria) => ({
          ...getDefaultCriteria(),
          point: criteria.criteriaGrades[0].description,
          scale: criteria.criteriaGrades[0].maxGrade || 0,
        }));
      }

      if (this.rubric.type === "Advanced") {
        this.criteria = this.rubric.criterias.map((criteria) => ({
          point: criteria.name,
          scale: 0,
          scales: criteria.criteriaGrades.map((scale) => ({
            description: scale.description,
            scale: scale.maxGrade,
          })),
        }));
      }

      if (this.$route?.query?.type === "preview") {
        this.isPreview = true;
      }
    }

    // init watch after all data initials
    this.$watch("scaleType", () => {
      this.isPreview = false;
    });
  },
};
</script>

<template>
  <div>
    <b-alert v-if="error" variant="danger" show> {{ error }}</b-alert>
    <b-form @submit="onSubmit">
      <h5>Details</h5>

      <template v-if="!editing">
        <b-form-group class="mb-0">
          <template #label> Type <span class="red-text">*</span></template>
          <b-form-radio
            v-model="scaleType"
            name="rubric-type-selector"
            value="Simple"
          >
            Simple
          </b-form-radio>
          <p class="text-muted small">
            Enter your grading criteria titles and a custom grade and let the AI
            do the rest.
            <br />
            <b-icon icon="journal-richtext"></b-icon>
            <a href="/examples/simple-rubric.html" target="_blank">
              See an example
            </a>
          </p>
          <b-form-radio
            v-model="scaleType"
            name="rubric-type-selector"
            value="Advanced"
          >
            Advanced <b-badge variant="info">✨ NEW</b-badge>
          </b-form-radio>
          <p class="text-muted small">
            Enter detailed grading information for each criteria. You can
            recreate your existing rubric here. Recommended for higher grading
            accuracy.
            <br />
            <b-icon icon="journal-richtext"></b-icon>
            <a href="/examples/advanced-rubric.html" target="_blank">
              See an example
            </a>
            <b-icon class="ml-2" icon="youtube"></b-icon>
            <a
              href="https://www.youtube.com/watch?v=-vFDEznm_DE"
              target="_blank"
            >
              Watch a training video
            </a>
          </p>
        </b-form-group>
      </template>

      <b-form-group id="name-group" label-for="name-input">
        <template #label>
          Rubric name <span class="red-text">*</span>
        </template>
        <b-form-input
          id="name-input"
          v-model="name"
          :state="canValidate ? !(name.length > 255) : null"
          placeholder="Enter name"
          type="text"
        ></b-form-input>
      </b-form-group>

      <rubric-intensity-radio-group
        v-if="isUsingAdvancedScale"
        horizontal
        v-model="intensity"
      />

      <h5>Grading criteria</h5>

      <div
        v-if="isUsingCustomScale"
        class="mode-toggle d-flex align-items-center"
      >
        <div class="mode-text mr-2">Mode</div>
        <b-form-radio-group
          class="toggle-button"
          v-model="isPreview"
          :options="[
            { text: this.editing ? 'Edit' : 'Create', value: false },
            { text: 'Preview rubric', value: true },
          ]"
          button-variant="outline-info"
          size="sm"
          name="isPreview"
          buttons
        ></b-form-radio-group>
      </div>

      <div v-if="isPreview">
        <rubricPreviewTable
          :criterias="criteria"
          :name="name"
          :scaleType="scaleType"
        />
      </div>
      <div v-else>
        <b-card v-for="(point, index) in criteria" :key="index" class="mb-3">
          <rubricScale
            label="Criteria title"
            description="The aspects of the writing performance that will be assessed. For example: Argument, Evidence, or Clarity."
            placeholder="Enter criteria title"
            :showScale="isUsingCustomScale && scaleType !== 'Advanced'"
            :showDelete="criteria.length > 1"
            :index="index"
            :value="{
              value: criteria[index].point,
              scale: criteria[index].scale,
            }"
            :can-validate="canValidate"
            @updateValue="updateCriteriaValue($event, index)"
            @updateScale="updateCriteriaScale($event, index)"
            @delete="deleteCriteria(index)"
          >
            <template v-if="isUsingAdvancedScale">
              <rubricScale
                v-for="(scale, scaleIndex) in criteria[index].scales"
                :key="scaleIndex"
                isDescriptor
                label="Criteria descriptors"
                description="Enter what the essay needs to demonstrate to get this grade. Ex: Essay is well structured."
                placeholder="Enter description"
                showScale
                textarea
                isUsingAdvancedScale
                showDelete
                :isDisabled="criteria[index].scales.length <= 1"
                :index="scaleIndex"
                :value="{
                  value: scale.description,
                  scale: scale.scale,
                }"
                :can-validate="canValidate"
                @updateValue="
                  updateCriteriaScaleDescriptionValue($event, index, scaleIndex)
                "
                @updateScale="
                  updateCriteriaScaleDescriptionScale($event, index, scaleIndex)
                "
                @delete="deleteCriteriaDescription(index, scaleIndex)"
              />
              <b-button
                class="mb-3"
                @click="() => addCriteriaDescription(index)"
              >
                <b-icon icon="plus"></b-icon>
                Add criteria descriptor
              </b-button>
            </template>
          </rubricScale>
        </b-card>
        <b-button v-if="canAddCriteriaPoint" @click="addCriteriaPoint">
          <b-icon icon="plus"></b-icon>
          Add another criteria
        </b-button>
      </div>

      <div v-if="!noButtons" class="mt-3 text-right">
        <b-button class="mr-2" to="/rubrics">Cancel</b-button>
        <b-button
          v-if="editing"
          class="mr-2"
          variant="danger"
          @click="showDeleteConfirmation"
        >
          Delete
        </b-button>
        <b-button type="submit" variant="info">{{ buttonText }}</b-button>
      </div>
    </b-form>
  </div>
</template>

<style scoped>
.mode-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
}
.mode-toggle {
  background-color: #f8f9fa;
  height: 48px;
  padding: 17px;
  border-radius: 4px;
  margin-top: 21px;
  margin-bottom: 15px;
}

.toggle-button {
  background-color: #ffffff;
}
</style>
