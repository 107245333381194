<script>
import formatDate from "@/utils/formatDate";

export default {
  name: "RubricCard",
  props: {
    rubric: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rubricId() {
      return this.rubric.id;
    },
    rubricName() {
      return this.rubric.name;
    },
    createdDate() {
      return formatDate(this.rubric.createdAt);
    },
    customGradingScale() {
      return ["Simple", "Advanced"].includes(this.rubric.type);
    },
    isAdvancedRubric() {
      return this.rubric.type === "Advanced";
    },
  },
};
</script>

<template>
  <div class="rubric-card mr-4 mb-3 d-flex flex-column">
    <div class="d-flex justify-content-between">
      <a
        v-b-tooltip.hover
        :title="rubricName.length > 25 ? rubricName : ''"
        :href="`/rubric/edit/${rubricId}`"
        class="rubric-name-link"
      >
        <h5 class="text-truncate">
          {{ rubricName }}
        </h5>
      </a>
    </div>
    <span class="light-grey-text text-date">
      Created on {{ createdDate }}
    </span>
    <div class="mt-2">
      <component
        :is="isAdvancedRubric ? 'ul' : 'div'"
        class="pl-3 mb-0 text-truncate"
      >
        <component
          :is="isAdvancedRubric ? 'li' : 'div'"
          v-for="(criteria, index) in rubric.criterias.slice(0, 8)"
          :key="index"
        >
          <span v-if="isAdvancedRubric">
            {{ criteria.name }}
          </span>
          <ul v-else class="pl-3 mb-0">
            <li
              v-for="(grades, index) in criteria.criteriaGrades"
              :key="index"
              v-b-tooltip.hover
              :title="grades.description.length > 25 ? grades.description : ''"
            >
              <div class="text-truncate">{{ grades.description }}</div>
            </li>
          </ul>
        </component>
      </component>
    </div>
    <div class="mt-auto">
      <b-badge v-if="customGradingScale" pill variant="info">
        {{ rubric.type }} grading scale
      </b-badge>
      <b-badge v-else pill variant="grey"> default grading scale </b-badge>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rubric-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 345px;

  ul {
    max-width: 100%;
  }
}

.rubric-name-link {
  width: 100%;
}
</style>
