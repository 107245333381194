import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";

export default {
  inject: ["growthBook"],
  methods: {
    resetLocalStorageSavedFormData() {
      localStorage.removeItem("suggestion_form:selectedAssignment");
      localStorage.removeItem("suggestion_form:selectedClass");
      localStorage.removeItem("suggestion_form:selectedRubric");
    },
    async redirectToRoute(vm, user) {
      this.resetLocalStorageSavedFormData();
      this.growthBook?.setAttributes({ email: user?.email });

      vm.$router.replace({ name: "dashboard" });

      openFeatureModalIfNeeded(vm);
    },
  },
};
