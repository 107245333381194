<script>
import { ToggleButton } from "vue-js-toggle-button";
import {
  applyCoupon,
  updateUser,
  cancel,
  pause,
  unpause,
  customerPortal,
  getSubscriptionInfo,
} from "../api";

import EssayMonthlyCounters from "@/components/dashboard/essay_monthly_counters.vue";
import PauseCancelSection from "@/components/account/subscription/pause_cancel_section.vue";
import PricingCard from "../components/account/pricing_card.vue";
import formatDate from "../utils/formatDate";
import capitalize from "../utils/capitalize";
import upgradePlanMixin from "../mixins/upgradePlan";
import ConfirmYearlyUpgradeModal from "@/components/account/subscription/confirm_yearly_upgrade_modal.vue";
import ConfirmMonthlyUpgradeModal from "@/components/account/subscription/confirm_monthly_upgrade_modal.vue";
import { BASIC_PLAN, LITE_PLAN, PRO_PLAN, PLAN_INFO } from "../constants";

export default {
  name: "AccountView",
  components: {
    ConfirmYearlyUpgradeModal,
    ConfirmMonthlyUpgradeModal,
    EssayMonthlyCounters,
    PricingCard,
    PauseCancelSection,
    ToggleButton,
  },
  mixins: [
    /*
    this.error
    this.upgradeLoading
    this.upgradeHandler
    this.upgradeCurrentPaidPlan
    */
    upgradePlanMixin,
  ],
  data() {
    return {
      currentSubscriptionAmount: "0",
      nextBillingDate: "",
      editing: false,
      error: "",
      loading: false,
      userUpdate: {
        firstName: this.$store.state.user.firstName,
        lastName: this.$store.state.user.lastName,
        email: this.$store.state.user.email,
        password: "********",
      },
      subscription: null,
      subscriptionLoading: true,
      manualSubscriptionChanges: false,
      yearlyToggle: true,
      paymentInterval: "month",
      confirmUpgradeModalProps: {
        newPlan: "Lite",
        proceedWithUpgrade: () => {},
        newPlanPrice: "$0/year",
        newPlanMonthlyPrice: "$0/mo",
      },
    };
  },
  computed: {
    basicPlan() {
      return this.$store.getters.basicPlan;
    },
    litePlan() {
      return this.$store.getters.litePlan;
    },
    proPlan() {
      return this.$store.getters.proPlan;
    },
    premiumPlan() {
      return this.$store.getters.premiumPlan;
    },
    user() {
      return this.$store.state.user || {};
    },
    username() {
      return `${capitalize(this.user?.firstName || "")} ${capitalize(
        this.user?.lastName || ""
      )}`;
    },
    currentPlan() {
      return this.user.plan;
    },
    isPaused() {
      return this.user.isPaused;
    },
    pausedUntil() {
      return this.isPaused ? formatDate(this.user.pausedUntil) : "";
    },
    discountData() {
      const discount = this.subscription?.discount;

      if (!discount) {
        return null;
      }

      const coupon = discount.coupon;

      return {
        discountEnd: formatDate(new Date(discount.end * 1000)),
        discountTitle: coupon.amount_off
          ? `${coupon.amount_off} {coupon.currency}`
          : `${coupon.percent_off}%`,
      };
    },
    scheduledToCancel() {
      return this.subscription?.cancel_at_period_end;
    },
    cancelAtDate() {
      if (this.scheduledToCancel) {
        return formatDate(new Date(this.subscription?.cancel_at * 1000));
      }
      return "";
    },
    isYearlyPlan() {
      return this.paymentInterval === "year";
    },
    basicPlanData() {
      return {
        name: PLAN_INFO.basic.name,
        price: PLAN_INFO.basic.monthlyPrice,
        description: PLAN_INFO.basic.description,
        items: PLAN_INFO.basic.features,
      };
    },
    litePlanData() {
      return {
        name: PLAN_INFO.lite.name,
        price: this.yearlyToggle
          ? PLAN_INFO.lite.yearlyPrice
          : PLAN_INFO.lite.monthlyPrice,
        description: PLAN_INFO.lite.description,
        items: PLAN_INFO.lite.features,
      };
    },
    proPlanData() {
      return {
        name: PLAN_INFO.pro.name,
        price: this.yearlyToggle
          ? PLAN_INFO.pro.yearlyPrice
          : PLAN_INFO.pro.monthlyPrice,
        description: PLAN_INFO.pro.description,
        items: PLAN_INFO.pro.features,
      };
    },
    premiumPlanData() {
      return {
        name: PLAN_INFO.premium.name,
        price: this.yearlyToggle
          ? PLAN_INFO.premium.yearlyPrice
          : PLAN_INFO.premium.monthlyPrice,
        description: PLAN_INFO.premium.description,
        items: PLAN_INFO.premium.features,
      };
    },
    canUpdateOrCancel() {
      return Boolean(!this.basicPlan && !this.manualSubscriptionChanges);
    },
    canUpgradeToPremium() {
      return (
        this.user.plan === BASIC_PLAN ||
        this.user.plan === LITE_PLAN ||
        this.user.plan === PRO_PLAN
      );
    },
    canUpgradeToPro() {
      return this.user.plan === BASIC_PLAN || this.user.plan === LITE_PLAN;
    },
    canUpgradeToLite() {
      return this.user.plan === BASIC_PLAN;
    },
  },
  methods: {
    async updateUserInfo() {
      try {
        this.loading = true;

        const { data } = await updateUser({
          currentUser: this.user,
          updatedUser: this.userUpdate,
        });

        this.loading = false;
        this.editing = false;
        this.error = "";

        this.$store.dispatch("setUser", data.user);
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
        this.editing = false;
      }
    },
    async resumeSubscription() {
      try {
        this.loading = true;

        await unpause(this);

        this.loading = false;
        this.error = "";
        this.$store.dispatch("setUser", {
          ...this.user,
          isPaused: false,
        });
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
    async pauseSubscription(pauseForMonths) {
      try {
        this.loading = true;

        const { data } = await pause({ pauseForMonths });

        this.loading = false;
        this.error = "";
        this.$store.dispatch("setUser", {
          ...this.user,
          ...data,
          isPaused: true,
        });
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
        this.$showToastError(
          error?.message ||
            "There was a problem processing your request, please try again or contact support.",
          {
            title: "Error!",
            solid: true,
          }
        );
        throw error;
      }
    },
    async cancelSubscription(reasons) {
      try {
        this.loading = true;

        await cancel(reasons);

        this.loading = false;
        this.error = "";
        this.subscription = {
          ...this.subscription,
          cancel_at_period_end: this.subscription.current_period_end,
          cancel_at: Date.now() / 1000,
        };
        this.$bvModal.hide("subscription_cancel_modal");
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
    async applyCoupon(coupon = "3-month-discount") {
      try {
        this.loading = true;
        await applyCoupon(coupon);
        await this.initSubscriptionData();
        this.loading = false;
        this.error = "";
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
    async createPortalSession() {
      try {
        this.loading = true;
        const session = await customerPortal();

        this.loading = false;
        this.error = "";

        // redirect to external custom stripe payment page
        window.location.href = session.data.url;
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
    async initSubscriptionData() {
      // note: can't fetch subscription if the user
      // is not a paid user with a subscription
      const { stripeSubscriptionId } = this.user;

      if (!this.basicPlan && stripeSubscriptionId) {
        try {
          const { data } = await getSubscriptionInfo();

          this.subscription = data;
          // interval can be either "month" or "year"
          this.currentSubscriptionAmount = (data.plan.amount / 100).toFixed(2);
          this.paymentInterval = data.plan.interval;

          this.nextBillingDate = new Date(data.current_period_end * 1000);
          this.subscriptionLoading = false;
        } catch (error) {
          this.subscriptionLoading = false;
          this.manualSubscriptionChanges = true;
        }
      } else if (!this.basicPlan && !stripeSubscriptionId) {
        this.subscriptionLoading = false;
        this.manualSubscriptionChanges = true;

        // Pricing for US Stripe Customers - we don't have a stripe id for them
        if (this.litePlan) {
          this.currentSubscriptionAmount = "8.99";
        } else if (this.proPlan) {
          this.currentSubscriptionAmount = "19.99";
        } else {
          this.currentSubscriptionAmount = "49.99";
        }
      } else {
        // user is on basic plan set loading to false
        // since no fetch needs to happen

        this.subscriptionLoading = false;
      }
    },
    upgradeWithConfirmation(plan) {
      // If a current subscriber is upgrading from a monthly to a yearly plan
      // then we need to show a confirmation modal
      if (this.yearlyToggle && !this.basicPlan && !this.isYearlyPlan) {
        this.confirmUpgradeModalProps = {
          newPlan: plan,
          proceedWithUpgrade: this.upgradeHandler,
          newPlanPrice: PLAN_INFO[plan].yearlyTotalPrice,
          newPlanMonthlyPrice: PLAN_INFO[plan].monthlyPrice,
        };
        this.$bvModal.show("confirm_yearly_upgrade_modal");
      } else {
        this.upgradeHandler(plan, this.yearlyToggle);
      }
    },
  },

  mounted() {
    if (this.$route.query.coupon && (this.basicPlan || this.litePlan)) {
      this.$bvToast.toast(
        `Coupon ${this.$route.query.coupon} successfully applied to your next upgrade to Pro or Premium plan.`,
        {
          title: "Success!",
          variant: "success",
          solid: true,
          autoHideDelay: 5000,
        }
      );
    }

    this.$trackEvent("plans_page_viewed", {
      plan: this.user.plan,
    });

    this.initSubscriptionData();
  },
};
</script>

<template>
  <b-container>
    <section>
      <essay-monthly-counters />
      <!-- Upgrade plan section, shows plans higher than current plan (all if on basic plan) -->
      <b-row v-if="!premiumPlan">
        <b-col md="12">
          <h1>Upgrade your plan</h1>
          <hr />
          <p class="mb-3">
            Please choose one of the following plans below to upgrade your
            current plan to. If you choose a monthly plan you will be billed
            once a month, if you choose a yearly plan you will be billed once a
            year.
          </p>
          <div class="d-flex align-items-center mb-4">
            <toggle-button
              class="mr-2 my-0"
              :value="yearlyToggle"
              :labels="{
                checked: 'Yearly',
                unchecked: 'Monthly',
              }"
              color="#6f42c1"
              :width="78"
              :height="30"
              @change="yearlyToggle = !yearlyToggle"
            />
            <span class="discount-text">Up to 30% off</span>
          </div>
        </b-col>
        <b-col v-if="upgradeLoading" xl="12">
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="50%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
        </b-col>
        <template v-else>
          <b-col v-if="canUpgradeToLite" md="4">
            <pricing-card
              :planName="litePlanData.name"
              :planPrice="litePlanData.price"
              :planDescription="litePlanData.description"
              :planItems="litePlanData.items"
              :show-upgrade="!manualSubscriptionChanges"
              :show-manual-upgrade="manualSubscriptionChanges"
              :show-yearly="yearlyToggle"
              @upgrade="upgradeWithConfirmation('lite')"
            />
          </b-col>
          <b-col v-if="canUpgradeToPro" md="4">
            <pricing-card
              :planName="proPlanData.name"
              :planPrice="proPlanData.price"
              :planDescription="proPlanData.description"
              :planItems="proPlanData.items"
              :show-upgrade="!manualSubscriptionChanges"
              :show-manual-upgrade="manualSubscriptionChanges"
              :show-yearly="yearlyToggle"
              @upgrade="upgradeWithConfirmation('pro')"
            />
          </b-col>
          <b-col v-if="canUpgradeToPremium" md="4">
            <pricing-card
              :planName="premiumPlanData.name"
              :planPrice="premiumPlanData.price"
              :planDescription="premiumPlanData.description"
              :planItems="premiumPlanData.items"
              :show-upgrade="!manualSubscriptionChanges"
              :show-manual-upgrade="manualSubscriptionChanges"
              :show-yearly="yearlyToggle"
              @upgrade="upgradeWithConfirmation('premium')"
            />
          </b-col>
        </template>
      </b-row>

      <!-- Current plan section -->
      <b-row>
        <b-col>
          <div>
            <h1>
              Current plan
              <span v-if="isPaused"> - Paused</span>
            </h1>
            <hr />
            <div class="mb-4" v-if="isPaused">
              <p>
                You plan is currently paused until {{ pausedUntil }}. You can
                resume your plan anytime below.
              </p>
              <div class="my-3">
                <b-button variant="info" @click="resumeSubscription">
                  Resume subscription
                </b-button>
              </div>
            </div>
            <p class="mb-4" v-else-if="discountData">
              You are currently on the {{ currentPlan }} plan with a
              {{ discountData.discountTitle }} discount until
              {{ discountData.discountEnd }}. The card below shows what you can
              do each month on this plan.
            </p>
            <p class="mb-4" v-else>
              You are currently on the {{ currentPlan }} plan. The card below
              shows what you can do each month on this plan.
            </p>

            <b-spinner v-if="subscriptionLoading"></b-spinner>
            <template v-else>
              <div v-if="basicPlan">
                <pricing-card
                  active-plan
                  :planName="basicPlanData.name"
                  :planPrice="'0'"
                  :planDescription="basicPlanData.description"
                  :planItems="basicPlanData.items"
                />
              </div>
              <div v-if="litePlan">
                <pricing-card
                  active-plan
                  :planName="litePlanData.name"
                  :planPrice="currentSubscriptionAmount"
                  :planDescription="litePlanData.description"
                  :planItems="litePlanData.items"
                  :canceled="cancelAtDate"
                  :showYearly="isYearlyPlan"
                  :nextBillingDate="nextBillingDate"
                />
              </div>
              <div v-if="proPlan">
                <pricing-card
                  active-plan
                  :planName="proPlanData.name"
                  :planPrice="currentSubscriptionAmount"
                  :planDescription="proPlanData.description"
                  :planItems="proPlanData.items"
                  :canceled="cancelAtDate"
                  :showYearly="isYearlyPlan"
                  :nextBillingDate="nextBillingDate"
                />
              </div>
              <div v-if="premiumPlan">
                <pricing-card
                  active-plan
                  :planName="premiumPlanData.name"
                  :planPrice="currentSubscriptionAmount"
                  :planDescription="premiumPlanData.description"
                  :planItems="premiumPlanData.items"
                  :canceled="cancelAtDate"
                  :showYearly="isYearlyPlan"
                  :nextBillingDate="nextBillingDate"
                />
              </div>
            </template>
          </div>
        </b-col>
      </b-row>

      <!-- Account information section -->
      <div class="mb-4">
        <b-row>
          <b-col>
            <b-alert v-if="error" variant="danger" show>
              {{ error }}
            </b-alert>

            <h1>Account</h1>
            <hr />
          </b-col>
        </b-row>
        <b-row v-if="editing" class="mb-3">
          <b-col>
            <b-alert variant="info" show>
              Only update the fields you want to change. Any fields not touched
              will retain their same values.
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4">
            <b>First name</b>
          </b-col>
          <b-col cols="8">
            <b-input
              v-if="editing"
              v-model="userUpdate.firstName"
              type="text"
            />
            <span v-else>{{ user.firstName }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4">
            <b>Last name</b>
          </b-col>
          <b-col cols="8">
            <b-input v-if="editing" v-model="userUpdate.lastName" type="text" />
            <span v-else>{{ user.lastName }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4">
            <b>Email</b>
          </b-col>
          <b-col cols="8">
            <b-input v-if="editing" v-model="userUpdate.email" type="text" />
            <span v-else>{{ user.email }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4">
            <b>Password</b>
          </b-col>
          <b-col cols="8">
            <b-input
              v-if="editing"
              v-model="userUpdate.password"
              type="password"
            />
            <span v-else>********</span>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <div v-if="editing">
              <b-button class="ml-2" variant="info" @click="updateUserInfo">
                Submit Changes
              </b-button>
              <b-button class="ml-2" @click="editing = false">
                Cancel editing
              </b-button>
            </div>

            <b-button v-else variant="info" @click="editing = true">
              Edit
            </b-button>
          </b-col>
        </b-row>
        <hr />
      </div>

      <b-row>
        <b-col>
          <div v-if="manualSubscriptionChanges" class="mt-2">
            <h1>Update subscription</h1>
            <hr />
            <p class="mb-4">
              Contact support at
              <a href="mailto:hello@essaygrader.ai">hello@essaygrader.ai</a>
              to update your subscription.
            </p>
          </div>
        </b-col>
      </b-row>

      <!-- Update payment section, shown when on a paid plan and not a custom plan -->
      <b-row>
        <b-col>
          <div v-if="canUpdateOrCancel" class="mt-2">
            <h1>Payment information</h1>
            <hr />
            <p class="mb-4">
              If you need to update your payment information to prevent service
              interruption, please click the update payment information button
              below. You will be redirected to update your current payment
              information we have on file.
            </p>

            <b-button variant="info" class="mr-2" @click="createPortalSession">
              Update payment information
            </b-button>
            <b-button variant="info" @click="createPortalSession">
              View past invoices
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- Cancel subscription section, shown when on a paid plan and not a custom plan -->
      <b-row>
        <b-col>
          <pause-cancel-section
            v-if="canUpdateOrCancel && !isPaused"
            :username="username"
            :isYearlyPlan="isYearlyPlan"
            :subscriptionLoading="subscriptionLoading"
            :scheduledToCancel="!!scheduledToCancel"
            :applyCoupon="applyCoupon"
            :cancelAtDate="cancelAtDate"
            :cancelSubscription="cancelSubscription"
            :pauseSubscription="pauseSubscription"
            :isLitePlan="litePlan"
            :isDiscounted="!!discountData"
            :updateToLitePlan="
              () =>
                upgradeCurrentPaidPlan({
                  planText: 'lite',
                  yearlyToggle,
                  isDowngrade: true,
                })
            "
          />
        </b-col>
      </b-row>
    </section>
    <confirm-yearly-upgrade-modal
      :newPlan="confirmUpgradeModalProps.newPlan"
      :proceed-with-upgrade="confirmUpgradeModalProps.proceedWithUpgrade"
      :newPlanPrice="confirmUpgradeModalProps.newPlanPrice"
    />
    <confirm-monthly-upgrade-modal
      :newPlan="confirmUpgradeModalProps.newPlan"
      :proceed-with-upgrade="confirmUpgradeModalProps.proceedWithUpgrade"
      :newPlanPrice="confirmUpgradeModalProps.newPlanMonthlyPrice"
    />
  </b-container>
</template>
